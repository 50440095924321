<template>
  <div id="iyashi-dark-text">
    <div class="inner-block">
      <p class="text">
        {{ $t("iyashiDome.darkText") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'
#iyashi-dark-text
    background-color: #2b2b2b
    text-align: center
    .inner-block
        .text
            padding: 70px 0
            font-family: 'roboto'
            font-size: 40px
            line-height: 55px
            color: #FDFDFD
            text-transform: uppercase
            max-width: 95%
@media only screen and (max-width: $breakpoint-large-pc)
  #iyashi-dark-text
    .inner-block
      .text
        padding: 60px 0
        font-size: 36px
        line-height: 48px
@media only screen and (max-width: $breakpoint-middle-pc)
  #iyashi-dark-text
    .inner-block
      .text
        padding: 45px 0
        font-size: 30px
        line-height: 44px
@media only screen and (max-width: $breakpoint-small-pc)
  #iyashi-dark-text
    .inner-block
      .text
        font-size: 28px
        max-width: 100%
        line-height: 38px
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-dark-text
    .inner-block
      .text
        padding: 36px 0
        font-size: 26px
        max-width: 100%
        line-height: 36px
</style>
