<template>
  <div id="feedback">
    <div class="inner-block flex-center">
      <ul class="contacts flex-center">
        <li class="contact location">
          <span class="icon"></span>{{ $t("contacts.location") }}
        </li>
        <li class="contact phone"><span class="icon"></span>+41764218787</li>
        <li class="contact email">
          <span class="icon"></span>info@swiss2526.com
        </li>
        <li class="contact instagram">
          <a href="https://www.instagram.com/swiss_2526"
            ><span class="icon"></span>@Swiss_2526</a
          >
        </li>
      </ul>

      <form action="" class="form">
        <input
          type="text"
          id="form_name"
          class="input name"
          :placeholder="$t('feedback.name')"
          v-model="form_name"
        />
        <input
          type="email"
          id="form_mail"
          class="input email"
          :placeholder="$t('feedback.email')"
          v-model="form_mail"
        />
        <input
          type="phone"
          class="input phone"
          id="form_phone"
          onkeyup="this.value = this.value.replace(/[^0-9+]/g, '')"
          :placeholder="$t('feedback.phone')"
          v-model="form_phone"
        />
        <textarea
          class="input message"
          id="form_message"
          :placeholder="$t('feedback.message')"
          v-model="form_message"
        />
        <button class="form-btn" @click.prevent="sendMail">
          {{ $t("feedback.sendButtonText") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  data() {
    return {
      form_name: "",
      form_mail: "",
      form_phone: "",
      form_message: "",
    };
  },
  methods: {
    sendMail() {
      let params = {
        form_name: document.getElementById("form_name").value,
        form_mail: document.getElementById("form_mail").value,
        form_phone: document.getElementById("form_phone").value,
        form_message: document.getElementById("form_message").value,
      };

      const serviceID = "service_ydnisit";
      const templateID = "template_cx2cqml";
      const publicKey = "ua6dUfQj7-d1NIi0-";

      emailjs.send(serviceID, templateID, params, publicKey);

      this.form_name = "";
      this.form_mail = "";
      this.form_phone = "";
      this.form_message = "";
    },
  },
};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

#feedback
    background-image: url(/src/assets/images/man.jpg)
    background-repeat: no-repeat
    background-size: contain
    background-position-y: center
    margin-top: 90px
    .inner-block
        flex-direction: column
        padding-left:20%
        .contacts
            justify-content: space-evenly
            font-family: 'roboto'
            width: 100%
            .contact
                display: flex
                justify-content: center
                flex-direction: column
                align-items: center
                font-size: 14px
                font-weight: 400
                font-family: 'roboto'
                a
                  display: flex
                  justify-content: center
                  flex-direction: column
                  align-items: center
                .icon
                    display: block
                    background-size: contain
                    background-position: center
                    background-repeat: no-repeat
                    width: 42px
                    height: 40px
                    margin: 12px 0
            .location
                .icon
                    background-image: url(/src/assets/images/icons/location.png)
            .phone
                .icon
                    background-image: url(/src/assets/images/icons/phone.png)
            .email
                .icon
                    background-image: url(/src/assets/images/icons/email.png)
            .instagram
                .icon
                    background-image: url(/src/assets/images/icons/instagram.png)
        .form
            display: flex
            flex-direction: column
            width: 100%
            padding: 70px 0
            width: 530px
            .input, textarea
                border: 1px solid #7a7a7a
                margin: 16px auto
                background-color: rgba(255, 255, 255, 0)
                padding: 8px 15px
                width: 100%
                font-size: 16px
                color: #BABABA
                font-family: Sans-serif
                &::placeholder
                    font-family: 'Orbitron'
                    font-size: 16
                    color: #BABABA
            .message
                height: 230px
                outline: none
            .form-btn
                font-size: 20px
                letter-spacing: 3.8px
                text-transform: uppercase
                font-family: 'orbitron'
                background-color: #087CCE
                padding: 12px 0
                width: 100%
                color: #fff
                margin: 18px auto
                transition: all 0.5s cubic-bezier(.25,.46,.45,.94)
                &:hover
                  background: rgb(2,0,36)
                  background: linear-gradient(101deg, rgba(2,0,36,1) 0%, rgba(35,170,221,1) 0%, rgba(40,179,206,1) 50%, rgba(53,203,162,1) 100%)
@media only screen and (max-width: $breakpoint-large-pc)
  #feedback
    margin-top: 60px
    .inner-block
      .contacts
        .contact
          font-size: 12px

          .icon
            width: 32px
            height: 30px
            margin: 9px 0
      .form
        padding: 45px 0
        width: 470px
        .input
          padding: 6px 9px
          margin: 9px 0
          font-size: 14px
          &::placeholder
            font-size: 12px
        .message
          height: 180px
        .form-btn
          font-size: 16px
          padding: 11px 0
          margin: 14px 0
@media only screen and (max-width: $breakpoint-middle-pc)
  #feedback
    background-size: auto 100%
    .inner-block
      padding-left: 30%
      background-position-y: 0
      .contacts
        .contact
          font-size: 10px
          .icon
            width: 22px
            height: 20px
            margin: 9px 0
      .form
        padding: 35px 0
        width: 290px
        .input
          padding: 6px 9px
          margin: 12px 0
          font-size: 10px
          &::placeholder
            font-size: 10px
        .message
          height: 130px
        .form-btn
          font-size: 12px
          padding: 7px 0
          margin: 10px 0
@media only screen and (max-width: $breakpoint-small-pc)
  #feedback
    background-size: 38% auto
    .inner-block
      padding-top: 0
      padding-left: 30%
      .contacts
        .contact
          font-size: 8px
          .icon
            width: 18px
            height: 16px
            margin: 7px 0
      .form
        padding: 26px 0
        width: 260px
        .input
          padding: 6px 9px
          margin: 5px 0
          font-size: 8px
          &::placeholder
            font-size: 7px
        .message
          height: 100px
        .form-btn
          font-size: 8px
          padding: 7px 0
          margin: 7px 0
@media only screen and (max-width: $breakpoint-tablet)
  #feedback
    background-position-x: 0px
    background-position-y: 0px
    background-size: 180%
    .inner-block
      padding-left: 30px
      .contacts
        flex-direction: column
        .contact
          font-size: 16px
          margin: 12px 0
          .icon
            width: 27px
            height: 40px
            margin: 7px 0
      .form
        padding: 46px 0
        width: 100%
        .input
          padding: 6px 9px
          margin: 15px 0
          font-size: 16px
          &::placeholder
            font-size: 14px
        .message
          height: 150px
        .form-btn
          font-size: 16px
          padding: 8px 0
          margin: 7px 0
</style>
