export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partner"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakte"])}
  },
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["englisch"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["französisch"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deutschland"])}
  },
  "homeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='colorful-text'>Vertriebspartner</span> für Sport, SPA und Gesundheit"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ziel von Swiss 2526 ist es, ein längeres, gesünderes und glücklicheres Leben zu unterstützen, indem das Potenzial und die Leistungsfähigkeit des Körpers maximiert werden."])},
  "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um mehr über unseren Partner zu erfahren"])},
  "contacts": {
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zürich, Schweiz"])}
  },
  "feedback": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "sendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])}
  },
  "iyashiDome": {
    "about": {
      "bigText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOMPETENZ MIT TECHNOLOGIE VERSTÄRKEN <br /><span class='dark-text'>IYASHI DÔME</span><br /> DIE REFERENZ JAPANISCHE SAUNA!"])},
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPANISCHE EXPERTISE UND IYASHI DÔME INFRATHERAPIE WERDEN SEIT FAST 20 JAHREN FÜR WOHLBEFINDEN, SCHÖNHEIT UND GESUNDHEIT ANGEWENDET!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bold'>Schöpfer der japanischen Sauna Iyashi Dôme und dank japanischer Spitzentechnologie seit fast 20 Jahren führend auf </span> dem globalen Infratherapiemarkt… <span class='margin'></span>Entgiftung, Schlankheitskur, Anti-Aging, körperliche Vorbereitung auf den Schlaf und Muskelregeneration… Erfahren Sie mehr über Iyashi Dôme, die globale und effektives Behandlungsgerät!"])}
      }
    },
    "darkIcons": {
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEWICHTSVERLUST"])},
      "wrinkles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALTEN"])},
      "skinElasticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELASTIZITÄT DER HAUT"])},
      "skinHydration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEUCHTIGKEIT DER HAUT"])}
    },
    "founder": {
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DER GRÜNDER SHOGORO UEMURA UND DIE JAPANISCHE SAUNA IYASHI DÔME"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das 2004 von seinem derzeitigen Manager Shogoro Uemura gegründete Unternehmen Iyashi Dôme wurde mit einem einfachen Prinzip gegründet: ein echtes Bedürfnis nach Wellness und Wiederherstellung des Gleichgewichts dank Infrarottherapie ansprechen.<span class='margin'></span>Der Iyashi Dôme ist ein Ferninfrarot-Behandlungsgerät für<span class='bold'> Wellness-, Schönheits- und Gesundheitsexperten, das auf fast 20 Jahre marktführender Produktentwicklung zurückgeht.</span><span class='margin'></span> In der japanischen Hochtechnologie verwurzelt, ist die Iyashi Dôme-Infratherapie eine risikofreie Technik, die sowohl sanft als auch kraftvoll ist. Es ermöglicht eine deutliche Erhöhung der Körpertemperatur, die sich auf <span class='bold'> Entgiftung, Schlankheit, Schlaf, Hautverjüngung, körperliche Vorbereitung, Muskelregeneration und Entspannung auswirkt.</span>"])},
        "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Stress, die Hektik des modernen Lebens, eine unausgewogene Ernährung mit schnellen und verarbeiteten Lebensmitteln, Umweltverschmutzung, Leistungsdruck, negative Emotionen, allgegenwärtige Selbstbeherrschung, Jetlag … all diese Dinge fordern den menschlichen Körper, der es muss verwenden Sie mehr Energie, um damit fertig zu werden. <span class='margin'></span><span class='margin'></span> Inspiriert von der traditionellen japanischen Praxis „Suna Ryoho“ habe ich die japanische Sauna Iyashi Dôme entwickelt, um einer größeren Anzahl von Menschen tiefe und effektive Entgiftungsbehandlungen zugänglich zu machen…”"])}
      }
    },
    "darkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETOX, SLIMMING, ANTI-AGEING, SCHLAF, KÖRPERLICHE VORBEREITUNG UND MUSKELAUFBAU, ENTSPANNUNG: DAS IYASHI DÔME IST EIN GLOBALES, EFFEKTIVES, PROFITABLES UND SICHERES GERÄT!"])},
    "benefits": {
      "windowHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KLINISCH NACHGEWIESENE, GEMESSENE VORTEILE!"])},
      "windowText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Iyashi Dôme bietet nicht nur eine „heilende“ Wirkung, sondern auch und vor allem eine vorbeugende Wirkung durch Ferninfrarotstrahlen.<br />Entgiftung, Schlankheitskur, Anti-Aging, Verbesserung des Schlafs, Erholung von Jetlag, Entspannung, Körperpflege Vorbereitung und Muskelregeneration, Entgiftung des Gehirns usw. Die Vorteile sind zahlreich!<br />Diese spezifischen Vorteile der Iyashi Dôme-Technologie wurden in klinischen Studien gemessen.<br />Zum Beispiel eine 30-minütige Iyashi Eine Dôme-Session entspricht einem 20-km-Lauf und der Ausscheidung von ca. 1.200 Milliliter Schweiß und 600 kcal!"])},
      "1iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIE GEBURT DER IYASHI DÔME"])},
      "1iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umweltverschmutzung, Stress, Lebensstil, schlechte Essgewohnheiten … Finden Sie heraus, wie der Iyashi Dôme aus einem tiefen Bedürfnis nach Entgiftung geboren wurde."])},
      "2iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IYASHI DÔME INFRATHERAPIE"])},
      "2iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferninfrarotstrahlen sind für das Pflanzenwachstum unerlässlich, aber sind Sie sich ihrer Vorteile für den menschlichen Körper bewusst?"])},
      "3iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNSERE TECHNOLOGIEN"])},
      "3iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyashi Dôme, die japanische High-Tech-Sauna, wurde für Gesundheits-, Wellness- und Schönheitsexperten entwickelt."])},
      "4iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VORTEILE"])},
      "4iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entgiftung, Schlankheit, Anti-Aging, körperliche Vorbereitung und Muskelregeneration, Verbesserung des Schlafs und Entspannung… Entdecken Sie die Vorteile der Iyahsi Dôme-Infratherapie."])}
    }
  }
}