<template>
  <div id="iyashi-founder">
    <div class="inner-block">
      <div class="start-images flex-center">
        <img src="../assets/images/logo-partner_1-2.png" alt="" class="image" />
        <img src="../assets/images/japanese.png" alt="" class="image" />
        <img src="../assets/images/logo-partner_1.jpg" alt="" class="image" />
      </div>

      <div class="article">
        <p class="heading">
          {{ $t("iyashiDome.founder.article.heading") }}
        </p>
        <p class="text" v-html="$t('iyashiDome.founder.article.text')"></p>

        <p class="quote" v-html="$t('iyashiDome.founder.article.quote')"></p>
        <img
          src="../assets/images/founder-signature.png"
          alt="signature"
          class="signature"
        />
      </div>

      <div class="end-images flex-center">
        <img src="../assets/images/end-image_1.jpg" alt="" class="image" />
        <img src="../assets/images/end-image_2.jpg" alt="" class="image" />
        <img src="../assets/images/end-image_3.jpg" alt="" class="image" />
        <img src="../assets/images/end-image_4.jpg" alt="" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'
#iyashi-founder
    background-color: #fff
    color: $partnerText
    font-family: 'roboto'
    .inner-block
        .start-images
            position: relative
            padding: 120px 0
            justify-content: space-between
            .image
                object-fit: cover
                max-width: 32%
                width: auto
            nav
              border: 1px solid red
        .article
            .heading
                font-size: 40px
                line-height: 50px
                margin-bottom: 20px
            .text
                font-size: 24px
                line-height: 34px
                .bold
                    font-weight: bold
                .margin
                    display: block
                    margin-top: 9px
            .quote
                font-size: 24px
                text-align: center
                line-height: 34px
                font-style: italic
                margin: 20px 0
                .margin
                    display: block
                    margin-top: 9px
            .signature
                margin: 0 auto
        .end-images
            padding: 120px 0
            justify-content: space-between
            .image
                max-width: 24%
@media only screen and (max-width: $breakpoint-large-pc)
  #iyashi-founder
    .inner-block
      .start-images
        padding: 90px 0
      .article
        .heading
          font-size: 36px
          line-height: 46px
          margin-bottom: 16px
        .text
          font-size: 22px
          line-height: 34px
        .quote
          font-size: 22px
          line-height: 34px
          margin: 30px 0
        .signature
          max-width: 40%
      .end-images
        padding: 80px 0
@media only screen and (max-width: $breakpoint-middle-pc)
  #iyashi-founder
    .inner-block
      .start-images
        padding: 69px 0
      .article
        .heading
          font-size: 30px
          line-height: 40px
          margin-bottom: 12px
        .text
          font-size: 18px
          line-height: 28px
          .margin
            margin-top: 6px
        .quote
          font-size: 18px
          line-height: 28px
          margin: 20px 0
          .margin
            margin-top: 6px
        .signature
          max-width: 40%
      .end-images
        padding: 50px 0
@media only screen and (max-width: $breakpoint-small-pc)
  #iyashi-founder
    .inner-block
      .start-images
        padding: 40px 0
      .article
        .heading
          font-size: 28px
          line-height: 36px
        .text
          font-size: 16px
          line-height: 24px
        .quote
          font-size: 16px
          line-height: 24px
          margin: 20px 0
        .signature
          max-width: 40%
      .end-images
        padding: 36px 0
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-founder
    .inner-block
      .start-images
        padding: 40px 0
        .image
          margin-top: 8px
      .article
        .heading
          font-size: 28px
          line-height: 36px
        .text
          font-size: 16px
          line-height: 24px
        .quote
          font-size: 16px
          line-height: 24px
          margin: 20px 0
        .signature
          max-width: 60%
      .end-images
        padding: 36px 0
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-founder
    .inner-block
      .start-images
        padding: 40px 0
        flex-wrap: wrap
        justify-content: center
        .image
          max-width: 47.5%
          margin-top: 6px
      .article
        .heading
          font-size: 20px
          line-height: 28px
          max-width: 70%
        .text
          font-size: 14px
          line-height: 20px
        .quote
          font-size: 14px
          line-height: 20px
          margin: 20px 0
        .signature
          max-width: 80%
      .end-images
        flex-wrap: wrap
        padding: 36px 0
        .image
          margin-top: 6px
          max-width: 47.5%
</style>
