<template>
  <button
    @click="scrollUp"
    class="scroll-up"
    :style="[
      scrolling
        ? {
            right: '20px',
          }
        : { right: '-100px' },
    ]"
  ></button>
</template>

<script>
export default {
  methods: {
    scrollUp() {
      window.scrollBy(0, -10000);
    },
  },
  props: {
    scrolling: Boolean,
  },
};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'

.scroll-up
    position: fixed
    bottom: 20px
    background-image: url(/src/assets/images/icons/arrow-up.svg)
    background-size: 15px
    background-repeat: no-repeat
    background-color: darken($dark, 4%)
    width: 40px
    height: 40px
    transition: 0.5s ease-in-out
    background-position: center
</style>
