import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../pages/HomeView.vue";
import IyashiDomeView from "../pages/IyashiDomeView.vue";

// swiss
import StartSection from "../components/StartSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import PartnersSection from "@/components/PartnersSection.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/",
        name: "Start",
        component: StartSection,
      },
      {
        path: "about",
        name: "about",
        component: AboutSection,
      },
      {
        path: "/",
        name: "partners",
        component: PartnersSection,
      },
    ],
  },
  {
    path: "/iyashi-dome",
    name: "partner-lyashi-dome",
    component: IyashiDomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
