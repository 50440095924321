export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partners"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])}
  },
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["french"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["germany"])}
  },
  "homeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport, SPA & Health <span class='colorful-text'>Distributor<span>"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swiss 2526’s target is to support longer, healthier and happier life by maximizing the body‘s potential and performance."])},
  "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to read more about our partner"])},
  "contacts": {
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurich, Switzerland"])}
  },
  "feedback": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "sendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])}
  },
  "iyashiDome": {
    "about": {
      "bigText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REINFORCING EXPERTISE WITH TECHNOLOGY<br /><span class='dark-text'>IYASHI DÔME</span><br />THE REFERENCE JAPANESE SAUNA!"])},
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPANESE EXPERTISE AND IYASHI DÔME INFRATHERAPY APPLIED TO WELLBEING, BEAUTY AND HEALTH FOR ALMOST 20 YEARS!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bold'>Creator of the Iyashi Dôme Japanese sauna and a leader in the global infratherapy market for almost 20 years thanks</span> to Japanese High Technology… <span class='margin'></span> Detox, slimming, anti-ageing, sleep physical preparation and muscle recovery… find out more about Iyashi Dôme, the global and effective treatment device!"])}
      }
    },
    "darkIcons": {
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEIGHT LOSS"])},
      "wrinkles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WRINKLES"])},
      "skinElasticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKIN ELASTICITY"])},
      "skinHydration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKIN HYDRATION"])}
    },
    "founder": {
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THE FOUNDER SHOGORO UEMURA AND THE IYASHI DÔME JAPANSESE SAUNA"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founded in 2004 by its current manager, Shogoro Uemura, the company Iyashi Dôme was created with a straightforward principle: address a true need for wellness and restoring balance thanks to infratherapy.<span class='margin'></span>The Iyashi Dôme is a far infrared treatment device for<span class='bold'> wellbeing, beauty and health professionals that stems for almost 20 years of market-leading product development.</span><span class='margin'></span> Rooted in Japanese High Technology, Iyashi Dôme infratherapy is a risk-free technique that is both gentle and powerful. It enables a distinct increase in body temperature which has an action on<span class='bold'> detox, slimming, sleep, skin rejuvenation, physical preparation,muscle recovery and relaxation.</span>"])},
        "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Stress, the frenzy of modern life, an imbalanced diet of fast and processed foods, pollution, pressure to perform, negative emotions, ever-present self-control, jet-lag… all these things take a toll on the human body that has to use more energy to cope.<span class='margin'></span><span class='margin'></span> Drawing inspiration from the traditional Japanese practice “Suna Ryoho”, I developed the Iyashi Dôme Japanese sauna to make deep and effective detox treatments available to a greater number of people…”"])}
      }
    },
    "darkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETOX, SLIMMING, ANTI-AGEING, SLEEP, PHYSICAL PREPARATION AND MUSCLE RECOVERY, RELAXATION: THE IYASHI DÔME IS A GLOBAL, EFFECTIVE, PROFITABLE AND SAFE DEVICE!"])},
    "benefits": {
      "windowHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLINICALLY PROVEN, MEASURED BENEFITS!"])},
      "windowText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not only does the Iyashi Dôme provides a “healing” action, it also and above all provides a preventive action through far infrared rays.<br />Detox, slimming, anti-ageing, improving sleep, recovering from jet lag, relaxation, physical preparation and muscle recovery, brain detox, etc. The benefits are numerous!<br />For that matter, these benefits specific to the Iyashi Dôme technology have been measured by clinical trials.<br />For example, one 30-min Iyashi Dôme session is equivalent to a 20-km run and to the elimination of approximately 1,200 milliliters of sweat and 600 kcal!"])},
      "1iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THE BIRTH OF THE IYASHI DÔME"])},
      "1iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pollution, stress, lifestyle, poor eating habits… Find out how the Iyashi Dôme was born out of a deep need of detox."])},
      "2iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IYASHI DÔME INFRATHERAPY"])},
      "2iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Far infrared rays are essential to plant growth, but are you aware of their benefits for the human body?"])},
      "3iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR TECHNOLOGIES"])},
      "3iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyashi Dôme, the High Technology Japanese sauna, was developed for health, wellbeing and beauty professionals."])},
      "4iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BENEFITS"])},
      "4iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detox, slimming, anti-ageing, physical preparation and muscle recovery, improving sleep and relaxation… Discover the benefits of Iyahsi Dôme infratherapy."])}
    }
  }
}