<template>
  <div id="partners">
    <div class="inner-block flex-center">
      <div class="image-wrapper flex-center">
        <router-link to="/iyashi-dome">
          <img
            src="../assets/images/logo-partner_1.jpg"
            alt=""
            class="logo-partner"
          />
          <small class="subtext">{{ $t("prompt") }}</small>
        </router-link>
      </div>
      <router-link to="/iyashi-dome" class="name-partner"
        >IYASHI DÔME</router-link
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

#partners
  padding: 87px 0 130px 0
  .inner-block
    justify-content: flex-start
    .name-partner
      font-family: 'roboto'
      font-size: 22px
      font-weight: 800
      padding: 12px
      color: $main
    .image-wrapper
      width: 50%
      position: relative
      .logo-partner
        max-width: 90%
      .subtext
        position: absolute
        bottom: -50px
        line-height: 20px
        left: 0
        color: rgba(255,255,255,0.7)
@media only screen and (max-width: $breakpoint-large-pc)
  #partners
    padding: 87px 0 130px 0
    .inner-block
      grid-gap: 16px 48px
      .name-partner
        font-size: 18px
@media only screen and (max-width: $breakpoint-middle-pc)
  #partners
    padding: 77px 0
    .inner-block
      grid-gap: 14px 48px
      .name-partner
        font-size: 16px
@media only screen and (max-width: $breakpoint-small-pc)
  #partners
    padding: 57px 0 80px 0
    .inner-block
      grid-gap: 12px 44px
      grid-template-columns: 40% auto
      .name-partner
        font-size: 12px
@media only screen and (max-width: $breakpoint-tablet)
  #partners
    padding: 40px 0
    .inner-block
      display: flex
      flex-direction: column
      .name-partner
        font-size: 20px
        padding: 16px
        margin-top: 70px
        order: -1
      .image-wrapper
        width: 70%
        .logo-partner
          max-width: 100%
@media only screen and (max-width: $breakpoint-mobile-large)
  #partners
    padding: 40px 0
    .inner-block
      .name-partner
        font-size: 20px
        padding: 16px
        margin-top: 60px
      .image-wrapper
        width: 90%
</style>
