<template>
  <section id="start">
    <div class="inner-block flex-center">
      <img src="logo.svg" alt="" class="logo" />
      <p class="description" v-html="$t('homeDescription')"></p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

#start
    height: 100vh
    background-image: url(/src/assets/images/back.jpg)
    background-repeat: no-repeat
    background-size: auto 100%
    .inner-block
        flex-direction: column
        .logo
            width: 195px
            margin: 40px 0
            height: 400px
        .description
            text-transform: uppercase
            font-size: 30px
            color: #fff
            margin: 40px 0
            letter-spacing: 0.1em
            text-align: center
            line-height: 24px
@media only screen and (max-width: $breakpoint-large-pc)
  #start
    .inner-block
      .logo
        width: 160px
        height: auto
      .description
        font-size: 24px
        margin: 25px 0
@media only screen and (max-width: $breakpoint-middle-pc)
  #start
    .inner-block
      .logo
        width: 120px
      .description
        font-size: 18px
        margin: 20px 0
@media only screen and (max-width: $breakpoint-small-pc)
  #start
    .inner-block
      .logo
        width: 90px
      .description
        font-size: 14px
        margin: 10px 0
@media only screen and (max-width: $breakpoint-tablet)
  #start
    .inner-block
      .logo
        width: 50%
      .description
        font-size: 20px
        line-height: 36px
        margin: 40px 0
</style>
