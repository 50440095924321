<template>
  <div id="iyashi-about" name="iyashi-about">
    <div class="inner-block flex-center">
      <p class="big-size-text" v-html="$t('iyashiDome.about.bigText')"></p>

      <div class="about-images flex-center">
        <img
          src="../assets/images/iyashi-about-1.jpg"
          alt=""
          class="about-image"
        />
        <img
          src="../assets/images/iyashi-about-2.jpg"
          alt=""
          class="about-image"
        />
        <img
          src="../assets/images/iyashi-about-3.jpg"
          alt=""
          class="about-image"
        />
      </div>

      <div class="article">
        <p class="heading" v-html="$t('iyashiDome.about.article.heading')"></p>
        <p class="text" v-html="$t('iyashiDome.about.article.text')"></p>
      </div>
    </div>
    <IyashiDarkIcons />
  </div>
</template>

<script>
import IyashiDarkIcons from "./IyashiDarkIcons.vue";
export default { components: { IyashiDarkIcons } };
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'
#iyashi-about
    font-family: 'roboto'
    background-color: #fff
    color: $partnerText
    .inner-block
        padding-bottom: 80px
        flex-direction: column
        .big-size-text
            text-align: center
            font-size: 40px
            line-height: 50px
            margin: 80px 0
            .dark-text
                display: block
                font-size: 80px
                line-height: 100px
                padding-top: 60px
                font-weight: bold
        .about-images
            justify-content: space-between
            .about-image
                max-width: 32%
        .article
            margin-top: 70px
            .heading
                font-size: 40px
                line-height: 50px
                margin: 20px 0
                font-weight: 400
                max-width: 75%
            .text
                font-size: 22px
                font-weight: 400
                line-height: 35px
                .bold
                    font-weight: bold
                .margin
                    display: block
                    margin-top: 9px
@media only screen and (max-width: $breakpoint-large-pc)
  #iyashi-about
    .inner-block
      padding-bottom: 70px
      .big-size-text
        font-size: 36px
        line-height: 48px
        margin: 60px 0
        .dark-text
          font-size: 70px
          line-height: 90px
          padding-top: 46px
      .article
        margin-top: 58px
        .heading
          font-size: 36px
          line-height: 48px
          margin: 18px 0
        .text
          font-size: 20px
          line-height: 32px
          .margin
            margin-top: 7px


@media only screen and (max-width: $breakpoint-middle-pc)
  #iyashi-about
    .inner-block
      padding-bottom: 60px
      .big-size-text
        font-size: 32px
        line-height: 42px
        margin: 46px 0
        .dark-text
          font-size: 60px
          line-height: 60px
          padding-top: 36px
      .article
        margin-top: 48px
        .heading
          font-size: 32px
          line-height: 42px
          margin: 12px 0
        .text
          font-size: 18px
          line-height: 30px
          .margin
            margin-top: 5px
@media only screen and (max-width: $breakpoint-small-pc)
  #iyashi-about
    .inner-block
      padding-bottom: 40px
      .big-size-text
        font-size: 30px
        line-height: 38px
        margin: 42px 0
        .dark-text
          font-size: 48px
          line-height: 42px
          padding-top: 36px
      .article
        margin-top: 38px
        .heading
          font-size: 28px
          line-height: 38px
          margin: 10px 0
        .text
          font-size: 16px
          line-height: 26px
          .margin
            margin-top: 5px
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-about
    .inner-block
      .big-size-text
        font-size: 26px
        line-height: 36px
        margin: 32px 0
        .dark-text
          font-size: 40px
          line-height: 42px
          padding-top: 36px
      .about-images
        flex-direction: column !important
        .about-image
          max-width: 90%
          margin-top: 8px
      .article
        margin-top: 28px
        .heading
          max-width: 90%
          font-size: 28px
          line-height: 38px
          margin: 10px 0
</style>
