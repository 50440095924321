<template>
  <div class="icons-block">
    <div class="inner-block flex-center">
      <div class="icon-block">
        <img
          src="../assets/images/icons/line-icon_1.png"
          alt="icon"
          class="icon"
        />
        <p class="number-value">-6kg</p>
        <small class="text-value">{{
          $t("iyashiDome.darkIcons.weight")
        }}</small>
      </div>
      <div class="icon-block">
        <img
          src="../assets/images/icons/line-icon_2.png"
          alt="icon"
          class="icon"
        />
        <p class="number-value">-18%</p>
        <small class="text-value">{{
          $t("iyashiDome.darkIcons.wrinkles")
        }}</small>
      </div>
      <div class="icon-block">
        <img
          src="../assets/images/icons/line-icon_3.png"
          alt="icon"
          class="icon"
        />
        <p class="number-value">60%</p>
        <small class="text-value">{{
          $t("iyashiDome.darkIcons.skinElasticity")
        }}</small>
      </div>
      <div class="icon-block">
        <img
          src="../assets/images/icons/line-icon_4.png"
          alt="icon"
          class="icon"
        />
        <p class="number-value">112%</p>
        <small class="text-value">{{
          $t("iyashiDome.darkIcons.skinHydration")
        }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import '../assets/styles/adaptive/sizes'
.icons-block
    justify-content: space-evenly
    background-color: #2b2b2b
    width: 100%
    padding: 80px 0
    .inner-block
        flex-direction: row !important
        justify-content: space-evenly
        .icon-block
            display: flex
            flex-direction: column
            align-items: center
            margin: 0 40px
            color: #fff
            .number-value
                margin-top: 20px
                font-size: 80px
                line-height: 100px
            .text-value
                margin: 10px 0
                font-size: 18px
                text-transform: uppercase
@media only screen and (max-width: $breakpoint-large-pc)
  .icons-block
    padding: 60px 0
    .inner-block
      .icon-block
        margin: 0 36px
        .number-value
          margin-top: 16px
          font-size: 72px
          line-height: 96px
        .text-value
          margin: 6px 0
          font-size: 16px

@media only screen and (max-width: $breakpoint-middle-pc)
  .icons-block
    padding: 50px 0
    .inner-block
      .icon-block
        margin: 0 32px
        .icon
          width: 90%
        .number-value
          margin-top: 14px
          font-size: 66px
          line-height: 92px
        .text-value
          margin: 6px 0
          font-size: 14px
@media only screen and (max-width: $breakpoint-small-pc)
  .icons-block
    padding: 42px 0
    .inner-block
      .icon-block
        margin: 0 12px
        .icon
          width: 80%
        .number-value
          margin-top: 10px
          font-size: 42px
          line-height: 62px
        .text-value
          margin: 6px 0
          font-size: 12px
@media only screen and (max-width: $breakpoint-tablet)
  .icons-block
    padding: 42px 0
    .inner-block
      display: grid !important
      grid-template-columns: 1fr 1fr
      grid-template-rows: 1fr 1fr
      .icon-block
        margin:  12px
        width: 90%
        .icon
          width: 40%
        .number-value
          margin-top: 10px
          font-size: 56px
          line-height: 68px
        .text-value
          margin: 16px 0
          font-size: 14px
</style>
