<template>
  <div id="about" name="about">
    <div class="inner-block flex-center">
      <p class="about-text">{{ $t("aboutText") }}</p>
      <img src="../assets/images/about.jpg" alt="" class="about-image" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

#about
    background-color: $lightBg
    color: #393939
    padding: 170px 0
    .inner-block
        justify-content: space-between
        .about-text
            font-family: 'roboto'
            font-weight: 500
            font-size: 20px
            max-width: 39%
        .about-image
            max-width: 550px
@media only screen and (max-width: $breakpoint-large-pc)
  #about
    padding: 125px 0
    .inner-block
      .about-text
        font-size: 18px
      .about-image
        max-width: 460px
@media only screen and (max-width: $breakpoint-middle-pc)
  #about
    padding: 65px 0
    .inner-block
      justify-content: space-evenly
      .about-text
        font-size: 16px
      .about-image
        max-width: 380px
@media only screen and (max-width: $breakpoint-small-pc)
  #about
    padding: 44px 0
    .inner-block

      .about-text
        font-size: 13.5px
        padding-right: 20px
      .about-image
        max-width: 270px
@media only screen and (max-width: $breakpoint-tablet)
  #about
    padding: 26px 0
    .inner-block
      flex-direction: column
      .about-text
        font-size: 16px
        max-width: 90%
        padding: 20px 0
      .about-image
        order: 1
        max-width: 90%
</style>
