<template>
  <div class="iyashi-dome">
    <SwissHeader :scrolling="scrolling" />
    <IyashiDomeStart />
    <IyashiAbout />
    <IyashiFounder />
    <IyashiDarkText />
    <IyashiBenefits />
    <ScrollUpButton :scrolling="scrolling" />
  </div>
</template>

<script>
import SwissHeader from "@/components/SwissHeader.vue";
import IyashiDomeStart from "@/components/IyashiDomeStart.vue";
import IyashiAbout from "@/components/IyashiAbout.vue";
import IyashiFounder from "@/components/IyashiFounder.vue";
import IyashiDarkText from "@/components/IyashiDarkText.vue";
import IyashiBenefits from "@/components/IyashiBenefits.vue";
import ScrollUpButton from "@/components/ScrollUpButton.vue";

export default {
  data() {
    return {
      scrolling: false,
    };
  },
  components: {
    SwissHeader,
    IyashiDomeStart,
    IyashiAbout,
    IyashiFounder,
    IyashiDarkText,
    IyashiBenefits,
    ScrollUpButton,
  },
  mounted() {
    let elem = document.querySelector(this.$route.hash);
    elem.scrollIntoView({ block: "start", behavior: "smooth" });

    document.querySelector(".iyashi-dome").style.scrollBehavior = "auto";
    document.addEventListener("scroll", () => {
      if (window.scrollY > 0 && window.innerWidth >= 720) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    });
    console.log(this.scrolling);
  },
};
</script>

<style></style>
