<template>
  <router-view></router-view>
  <FeedbackSection />
</template>

<script>
import FeedbackSection from "./components/FeedbackSection.vue";

export default {
  name: "App",

  components: {
    FeedbackSection,
  },
};
</script>

<style lang="sass">
@import './assets/styles/index'
#app
  background-color: $dark
  color: #fff
html
  scroll-behavior: smooth
.inner-block
  max-width: 1340px
  padding: 0 100px
  // border: 1px solid red
  margin: 0 auto
  height: 100%
@media only screen and (max-width: $breakpoint-large-pc)
  .inner-block
    padding: 0 120px
@media only screen and (max-width: $breakpoint-middle-pc)
  .inner-block
    padding: 0 100px
@media only screen and (max-width: $breakpoint-small-pc)
  .inner-block
    padding: 0 90px
@media only screen and (max-width: $breakpoint-tablet)
  .inner-block
    padding: 0 30px
</style>
