<template>
  <SwissHeader :scrolling="scrolling" />
  <StartSection />
  <AboutSection />
  <PartnersSection />
  <ScrollUpButton :scrolling="scrolling" />
</template>

<script>
import StartSection from "@/components/StartSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import PartnersSection from "@/components/PartnersSection.vue";
import SwissHeader from "@/components/SwissHeader.vue";
import ScrollUpButton from "@/components/ScrollUpButton.vue";
export default {
  data() {
    return {
      scrolling: false,
    };
  },
  components: {
    StartSection,
    AboutSection,
    PartnersSection,
    SwissHeader,
    ScrollUpButton,
  },
  mounted() {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 0 && window.innerWidth >= 720) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    });
  },
};
</script>

<style></style>
