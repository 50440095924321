<template>
  <div id="iyashi-benefits">
    <div class="window-section">
      <div class="inner-block">
        <div class="window">
          <p class="heading">{{ $t("iyashiDome.benefits.windowHeading") }}</p>
          <p class="text" v-html="$t('iyashiDome.benefits.windowText')"></p>
        </div>
      </div>
    </div>
    <div class="icons-block">
      <div class="inner-block flex-center">
        <div class="icon-block">
          <img src="../assets/images/benefit_1.png" alt="icon" class="icon" />
          <p class="heading">{{ $t("iyashiDome.benefits.1iconHeading") }}</p>
          <p class="desc">
            {{ $t("iyashiDome.benefits.1iconText") }}
          </p>
        </div>
        <div class="icon-block">
          <img src="../assets/images/benefit_2.png" alt="icon" class="icon" />
          <p class="heading">{{ $t("iyashiDome.benefits.2iconHeading") }}</p>
          <p class="desc">
            {{ $t("iyashiDome.benefits.2iconText") }}
          </p>
        </div>
        <div class="icon-block">
          <img src="../assets/images/benefit_3.png" alt="icon" class="icon" />
          <p class="heading">{{ $t("iyashiDome.benefits.3iconHeading") }}</p>
          <p class="desc">
            {{ $t("iyashiDome.benefits.3iconText") }}
          </p>
        </div>
        <div class="icon-block">
          <img src="../assets/images/benefit_4.png" alt="icon" class="icon" />
          <p class="heading">{{ $t("iyashiDome.benefits.4iconHeading") }}</p>
          <p class="desc">
            {{ $t("iyashiDome.benefits.4iconText") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'
#iyashi-benefits
    background-color: #fff
    color: $partnerText
    font-family: 'roboto'
    .window-section
        background-image: url(/src/assets/images/iyashi-benefit-image.jpg)
        background-size: cover
        background-repeat: no-repeat
        padding: 60px 0 120px 0
        .inner-block
            .window
                background-color: #FFFFFFD9
                padding: 30px
                max-width: 39%
                .heading
                    font-size: 24px
                    font-weight: bold
                    max-width: 80%
                    line-height: 32px
                    margin-bottom: 20px
                .text
                    font-size: 20px
                    line-height: 30px
    .icons-block
        padding: 120px 0
        .inner-block
            justify-content: center
            align-items: flex-start
            .icon-block
                display: flex
                text-align: center
                flex-direction: column
                align-items: center
                max-width: 23.9%
                .heading
                    font-size: 24px
                    padding: 0 10px
                    line-height: 32px
                    font-weight: bold
                .desc
                    padding: 20px 10px
                    font-size: 16.5px
                    line-height: 26px
@media only screen and (max-width: $breakpoint-large-pc)
  #iyashi-benefits
    .window-section
      padding: 46px 0 98px 0
      .inner-block
        .window
          padding: 22px
          .heading
            font-size: 22px
            line-height: 30px
            margin-bottom: 16px
          .text
            font-size: 18px
            line-height: 28px
    .icons-block
      padding: 90px 0
      .inner-block
        .icon-block
          .icon
            max-width: 50%
          .heading
            font-size: 22px
            line-height: 30px
          .desc
            font-size: 14px
            padding: 16px 0
            line-height: 22px
@media only screen and (max-width: $breakpoint-middle-pc)
  #iyashi-benefits
    .window-section
      padding: 30px 0 70px 0
      .inner-block
        .window
          padding: 16px
          .heading
            font-size: 18px
            line-height: 26px
            margin-bottom: 12px
          .text
            font-size: 16px
            line-height: 24px
    .icons-block
      padding: 60px 0
      .inner-block
        .icon-block
          .icon
            max-width: 45%
          .heading
            font-size: 20px
            line-height: 28px
          .desc
            font-size: 12px
            padding: 10px 0
            line-height: 18px
@media only screen and (max-width: $breakpoint-small-pc)
  #iyashi-benefits
    .window-section
      background-position: center
      padding: 20px 0 50px 0
      .inner-block
        .window
          padding: 12px
          max-width: 48%
          .heading
            font-size: 16px
            line-height: 22px
            margin-bottom: 10px
          .text
            font-size: 14px
            line-height: 22px
    .icons-block
      padding: 30px 0
      .inner-block
        justify-content: space-between
        .icon-block
          .icon
            max-width: 55%
          .heading
            font-size: 14px
            line-height: 20px
          .desc
            font-size: 12px
            padding: 10px 5px
            line-height: 18px
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-benefits
    .window-section
      background-position: center
      padding: 20px 0 50px 0
      .inner-block
        .window
          padding: 12px
          max-width: 88%
          .heading
            font-size: 16px
            line-height: 22px
            margin-bottom: 10px
          .text
            font-size: 14px
            line-height: 22px
    .icons-block
      padding: 30px 0
      .inner-block
        justify-content: space-between
        flex-wrap: wrap
        .icon-block
          margin-top: 14px
          padding: 0
          max-width: 45%
          .icon
            max-width: 55%
          .heading
            font-size: 14px
            line-height: 20px
          .desc
            font-size: 12px
            padding: 10px 5px
            line-height: 18px
</style>
