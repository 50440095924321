export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domicile"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les partenaires"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacts"])}
  },
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anglaise"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["french"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["française"])}
  },
  "homeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='colorful-text'>Distributeur</span> Sport, SPA & Santé"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objectif de Swiss 2526 est de favoriser une vie plus longue, plus saine et plus heureuse en maximisant le potentiel et les performances du corps."])},
  "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour en savoir plus sur notre partenaire"])},
  "contacts": {
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurich, Suisse"])}
  },
  "feedback": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "sendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "iyashiDome": {
    "about": {
      "bigText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENFORCEMENT DE L'EXPERTISE AVEC LA TECHNOLOGIE <br /><span class='dark-text'>IYASHI DÔME</span><br /> LE SAUNA JAPONAIS DE RÉFÉRENCE!"])},
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'EXPERTISE JAPONAISE ET L'INFRATHÉRAPIE IYASHI DÔME APPLIQUÉES AU BIEN-ÊTRE, À LA BEAUTÉ ET À LA SANTÉ DEPUIS PRÈS DE 20 ANS!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bold'>Créateur du sauna japonais Iyashi Dôme et leader sur le marché mondial de l'infrathérapie depuis près de 20 ans grâce </span> à la Haute Technologie Japonaise… <span class='margin'></span> Détox, minceur, anti-âge, préparation physique au sommeil et récupération musculaire… découvrez Iyashi Dôme, le sauna mondial et dispositif de traitement efficace!"])}
      }
    },
    "darkIcons": {
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERTE DE POIDS"])},
      "wrinkles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WRINKLES"])},
      "skinElasticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LES RIDES"])},
      "skinHydration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HYDRATATION DE LA PEAU"])}
    },
    "founder": {
      "article": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LE FONDATEUR SHOGORO UEMURA ET LE SAUNA JAPONAIS IYASHI DÔME"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondée en 2004 par son actuel dirigeant, Shogoro Uemura, la société Iyashi Dôme est née d'un principe simple : répondre à un véritable besoin de bien-être et de rééquilibrage grâce à l'infrathérapie.<span class='margin'></span> Le Iyashi Dôme est un appareil de <span class='bold'>traitement infrarouge lointain destiné aux professionnels du bien-être, de la beauté et de la santé, issu de près de 20 ans de développement de produits leaders sur le marché. </span><span class='margin'></span> Ancrée dans la Haute Technologie Japonaise, l'infrathérapie Iyashi Dôme est une technique sans risque, à la fois douce et puissante. Il permet une nette augmentation de la température corporelle qui a une action sur la détox, l'amincissement, le sommeil, le rajeunissement cutané, la préparation physique, la récupération musculaire et la relaxation.</.span>"])},
        "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le stress, la frénésie de la vie moderne, une alimentation déséquilibrée de fast-foods et d'aliments transformés, la pollution, la pression de la performance, les émotions négatives, la maîtrise de soi omniprésente, le décalage horaire… toutes ces choses mettent à rude épreuve le corps humain qui doit utiliser plus d'énergie pour faire face.<span class='margin'></span><span class='margin'></span> S'inspirant de la pratique traditionnelle japonaise “Suna Ryoho” », j'ai développé le sauna japonais Iyashi Dôme pour rendre accessible au plus grand nombre des soins détox profonds et efficaces…"])}
      }
    },
    "darkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉTOX, MINCEUR, ANTI-ÂGE, SOMMEIL, PRÉPARATION PHYSIQUE ET RÉCUPÉRATION MUSCULAIRE, DÉTENTE : L'IYASHI DÔME EST UN DISPOSITIF GLOBAL, EFFICACE, RENTABLE ET SÉCURISÉ!"])},
    "benefits": {
      "windowHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DES BÉNÉFICES CLINIQUEMENT PROUVÉS ET MESURÉS!"])},
      "windowText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Iyashi Dôme a non seulement une action « cicatrisante », mais aussi et surtout une action préventive grâce aux infrarouges lointains.<br />Détox, minceur, anti-âge, amélioration du sommeil, récupération du décalage horaire, relaxation, préparation et récupération musculaire, détox du cerveau, etc. Les bienfaits sont nombreux !<br />D'ailleurs, ces bienfaits propres à la technologie Iyashi Dôme ont été mesurés par des essais cliniques.<br />Par exemple, un Iyashi de 30 min La séance Dôme équivaut à une course de 20 km et à l'élimination d'environ 1 200 millilitres de sueur et 600 kcal!"])},
      "1iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LA NAISSANCE DU DÔME IYASHI"])},
      "1iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pollution, stress, hygiène de vie, mauvaises habitudes alimentaires… Découvrez comment le Iyashi Dôme est né d'un profond besoin de détox."])},
      "2iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFRATHÉRAPIE IYASHI DÔME"])},
      "2iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les infrarouges lointains sont essentiels à la croissance des plantes, mais connaissez-vous leurs bienfaits pour le corps humain ?"])},
      "3iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOS TECHNOLOGIES"])},
      "3iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyashi Dôme, le sauna japonais de Haute Technologie, a été développé pour les professionnels de la santé, du bien-être et de la beauté."])},
      "4iconHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVANTAGES"])},
      "4iconText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détox, minceur, anti-âge, préparation physique et récupération musculaire, amélioration du sommeil et relaxation… Découvrez les bienfaits de l'infrathérapie Iyahsi Dôme."])}
    }
  }
}