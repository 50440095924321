<template>
  <header
    class="header"
    :style="[
      darkHeader
        ? {
            backgroundColor: '#a5a5a5',
          }
        : { backgroundColor: 'rgba(255,255,255,0)' },
    ]"
  >
    <div class="inner-block">
      <a
        v-if="visibilityHomeLink"
        @click="this.$router.push({ name: 'home' })"
        href="/"
        class="nav-item_home"
        >{{ $t("nav.home") }}</a
      >
      <nav class="nav flex-center" v-else>
        <a @click="scrollInto('start')" class="nav-item">{{
          $t("nav.home")
        }}</a>
        <a @click="scrollInto('about')" class="nav-item">{{
          $t("nav.about")
        }}</a>
        <a @click="scrollInto('partners')" class="nav-item">{{
          $t("nav.partners")
        }}</a>
        <a @click="scrollInto('feedback')" class="nav-item">{{
          $t("nav.contacts")
        }}</a>
      </nav>
      <LanguageSwitcher />
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher.vue";
export default {
  props: {
    scrolling: Boolean,
  },
  computed: {
    darkHeader() {
      if (this.scrolling || this.$route.path === "/iyashi-dome") {
        return true;
      } else {
        return false;
      }
    },
    visibilityHomeLink() {
      if (this.$route.path === "/iyashi-dome") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    scrollInto(id) {
      document.getElementById(id).scrollIntoView();
    },
  },
  components: { LanguageSwitcher },
};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

.header
    position: fixed
    height: 67px
    width: 100%
    z-index: 99
    transition: all 0.3s ease 0s
    .inner-block
        display: flex
        align-items: center
        .nav-item_home
          font-size: 1.3rem
          margin-right: auto
        .nav
            display: flex
            justify-content: center
            flex: 1
            .nav-item
                color: #fff
                position: relative
                text-transform: uppercase
                font-family: "orbitron"
                font-weight: 500
                margin: 0 25px
                padding: 7px 0
                background-image: linear-gradient(currentColor, currentColor)
                background-position: 0% 100%
                background-repeat: no-repeat
                background-size: 0% 2px
                transition: background-size .3s
                font-size: 18px
                color: white
                &:hover
                    background-size: 100% 2px
@media only screen and (max-width: $breakpoint-large-pc)
  .header
    height: 50px
    .inner-block
      .nav-item_home
        font-size: 1.2rem
      .nav
        max-width: 80%
        margin-left: auto
        .nav-item
          margin: 0 15px
          padding: 5px 0
          font-size: 16px
@media only screen and (max-width: $breakpoint-middle-pc)
  .header
    height: 36px
    .inner-block
      .nav-item_home
        font-size: 1rem
      .nav
        max-width: 78%
        .nav-item
          font-size: 10px
          background-size: 0% 1px
          &:hover
            background-size: 100% 1px
@media only screen and (max-width: $breakpoint-small-pc)
  .header
    height: 30px
    .inner-block
      .nav-item_home
        font-size: 0.8rem
      .nav
        max-width: 100%
        padding-left: 10%
        .nav-item
          font-size: 9.5px
          margin: 0 7px
@media only screen and (max-width: $breakpoint-tablet)
  .header
    position: absolute
    height: 100%
    padding: 20px 0
    max-height: 60px
    .inner-block
      justify-content: center
      .nav-item_home
        font-size: 1.3rem
      .nav
        display: none
      .languages
        margin-left: 0
@media only screen and (max-width: $breakpoint-mobile-large)
  .header
    .inner-block
      .nav-item_home
        font-size: 1.1rem
</style>
