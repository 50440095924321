<template>
  <div id="iyashi-dome-start">
    <div class="inner-block">
      <img
        src="../assets/images/iyashi-dome-logo.png"
        alt="logo"
        class="logo-partner"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import "../assets/styles/base/colors"
@import "../assets/styles/adaptive/sizes"
#iyashi-dome-start
  height: 90vh
  background-image: url(/src/assets/images/iyashi_bg.jpg)
  background-size: cover
  background-position:  center
  background-repeat: no-repeat
  .inner-block
    display: flex
    align-items: center
    justify-content: flex-end
    .logo-partner
      width: 48%
@media only screen and (max-width: $breakpoint-tablet)
  #iyashi-dome-start
    background-position: center
    .inner-block
      justify-content: center
      .logo-partner
        width: 67%
@media only screen and (max-width: $breakpoint-mobile-large)
  #iyashi-dome-start
    .inner-block
      justify-content: center
      .logo-partner
        width: 90%
</style>
