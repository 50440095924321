<template>
  <ul class="languages flex-center">
    <li class="language">
      <a href="#" @click.prevent="setLocale('en')">
        <img
          src="/flags/english.jpg
                "
          alt="flag en"
          class="flag"
      /></a>
    </li>
    <li class="language">
      <a href="#" @click.prevent="setLocale('fr')"
        ><img
          src="/flags/french.jpg
        "
          alt="flag fr"
          class="flag"
      /></a>
    </li>
    <li class="language">
      <a href="#" @click.prevent="setLocale('ge')"
        ><img
          src="/flags/germany.jpg
        "
          alt="flag ge"
          class="flag"
      /></a>
    </li>
  </ul>
</template>

<script>
export default {
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style lang="sass">
@import '../assets/styles/base/colors'
@import '../assets/styles/adaptive/sizes'

.languages
    .language
        width: 25px
        height: 15px
        margin: 0 10px
        a
            img
                max-width: 100%
                max-height: 100%
@media only screen and (max-width: $breakpoint-large-pc)
  .languages
    .language
      width: 22px
      height: 13px
      margin: 0 8px
@media only screen and (max-width: $breakpoint-middle-pc)
  .languages
    .language
      width: 20px
      height: 10px
      margin: 0 5px
@media only screen and (max-width: $breakpoint-small-pc)
  .languages
    .language
      width: 15px
      height: 8px
      margin: 0 4px
@media only screen and (max-width: $breakpoint-tablet)
  .languages
    .language
      width: 36px
      height: 24px
      margin: 0 8px
@media only screen and (max-width: $breakpoint-mobile-large)
  .languages
    .language
      width: 30px
      height: 14px
      margin: 0 4px
</style>
